(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerJSConfig', ['$sce', 'utilities', filemakerJSConfig]);

	function filemakerJSConfig($sce, utilities) {
		return {
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function config() {
			return {
				id: 8,
				name: 'FileMaker',
				propertyName: 'filemakerJS',
				getApiConnection: null,
				available:
					utilities.getDBKPlatform() === 'dbkfmjs' ||
					utilities.getDBKPlatform() === 'dbkfmwd',
				editable: true,
				isPrimary: true,
				async: true,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: 'images/ClarisBig.png',
				sourceHelp: $sce.trustAsHtml(
					'<p>Please make sure the steps in the <a href="https://docs.dayback.com/article/174-preparing-your-file" target="_blank">Preparing Your File</a> article are complete before beginning the set-up here.</p>Begin by selecting the layout associated with this calendar. This tells DayBack where to perform the finds for this calendar and which table occurence this calendar represents. Please make sure DayBack\'s <a href="https://docs.dayback.com/article/175-required-fields" target="_blank">required fields</a> have been added to the table occurence for this layout:<br/><br/><ul><li>DBk_Expression</li><li>DBk_JSON</li><li>DBk_JSON_ListOf</li><li>DBk_EventSummaryCalc</li><li>DBk_TimestampStartCalcNum</li><li>DBk_TimestampEndCalcNum</li></ul> You can also give the calendar a name and specify other calendar settings on this tab. Please click the "Details..." button below each setting for more information.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					'<h4>Please map each of the fields below to a field in your FileMaker table.</h4>If you don\'t have a particular field, or don\'t want your calendar users to see the field, just ​un-check​ the enabled check-box beside the field. If you don\'t see a checkbox it means that field is required for the calendar to work. "Label" defines the field label in the popover. Once you have completed the field mapping, click "Validate Field Mapping" at the bottom of the page to check your work.'
				),
				contactsHelp: $sce.trustAsHtml(
					'<h4>Describe the contacts related to this calendar</h4>All fields are required for this calendar to work with related contacts.'
				),
				projectsHelp: $sce.trustAsHtml(
					'<h4>Describe the projects related to this calendar</h4>All fields are required for this calendar to work with related projects.'
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				useISOTimestamp: true,
				customActionsEditable: true,

				contactData: {
					searchLayout: {
						setting: 'searchLayout',
						visible: 'true',
						displayValue: 'Search Layout',
						defaultValue: '',
						format: 'select',
						helptext:
							'The name of the layout where DayBack will perform finds when looking up a contact. Please use a layout with no script triggers attached',
						required: true,
					},
					searchField: {
						setting: 'searchField',
						visible: 'true',
						displayValue: 'Search Field',
						defaultValue: '',
						format: 'select',
						required: true,
						helptext:
							'The name of the contact field to perform the contact search from DayBack. An indexed field is recommended for faster searching. The contact name is typically used here, but any field can be used if you want to search additional criteria besides the contact name.',
					},
					displayField: {
						setting: 'displayField',
						visible: 'true',
						displayValue: 'Display Field',
						defaultValue: '',
						format: 'select',
						required: true,
						helptext:
							'The name of the contact field to display when showing the results of contact search. A calculated field can be used here if you want the user to see additional information besides the contact name.',
					},
					navigationLayout: {
						setting: 'navigationLayout',
						visible: 'true',
						displayValue: 'Navigation Layout',
						defaultValue: '',
						format: 'select',
						required: false,
						helptext:
							'The name of the layout where the user will navigate then clicking the navigate to contact button on the DayBack Popover.',
					},
				},
				projectData: {
					searchLayout: {
						setting: 'searchLayout',
						visible: 'true',
						displayValue: 'Search Layout',
						defaultValue: '',
						format: 'select',
						required: true,
						helptext:
							'The name of the layout where DayBack will perform finds when looking up a project. Please use a layout with no script triggers attached',
					},
					searchField: {
						setting: 'searchField',
						visible: 'true',
						displayValue: 'Search Field',
						defaultValue: '',
						format: 'select',
						required: true,
						helptext:
							'The name of the project field to perform the project search from DayBack. An indexed field is recommended for faster searching. The project name is typically used here, but any field can be used if you want to search additional criteria besides the project name.',
					},
					displayField: {
						setting: 'displayField',
						visible: 'true',
						displayValue: 'Display Field',
						defaultValue: '',
						format: 'select',
						required: true,
						helptext:
							'The name of the project field to display when showing the results of project search. A calculated field can be used here if you want the user to see additional information besides the project name.',
					},
					navigationLayout: {
						setting: 'navigationLayout',
						visible: 'true',
						displayValue: 'Navigation Layout',
						defaultValue: '',
						format: 'select',
						required: false,
						helptext:
							'The name of the layout where the user will navigate then clicking the navigate to project button on the DayBack Popover.',
					},
				},
				settings: {
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 8,
					},
					layoutName: {
						setting: 'layoutName',
						visible: true,
						displayValue: 'Layout Name',
						defaultValue: '',
						helptext:
							'The name of the FileMaker layout associated with this calendar. Finds will be performed on this layout for gathering and rendering the events in DayBack, so please specify a layout with no script triggers for faster performance. This layout will also tell DayBack which table occurence is associated with this calendar. Please make sure all DayBack\'s <a href="https://docs.dayback.com/article/175-required-fields" target="_blank">required fields</a> have been added to the table occurence. Click validate below to check if DayBack can find the required fields in the specified table occurence. Note that these fields do <i>not</i> need to be on your layout.',
						format: 'select',
					},
					tableName: {
						setting: 'tableName',
						visible: true,
						displayValue: 'Table Occurrence Name',
						defaultValue: '',
						helptext:
							'The name of the FileMaker table occurrence that this calendar represents. Only tables to which DayBack\'s required fields have been added will be listed here. If your desired table occurrence is not in the drop-down list, please review <a href="https://docs.dayback.com/article/175-required-fields" target="_blank">required fields</a>. ',
						format: 'select',
					},
					contactPrimaryKey: {
						setting: 'contactPrimaryKey',
						visible: false,
						defaultValue: '',
					},
					projectPrimaryKey: {
						setting: 'projectPrimaryKey',
						visible: false,
						defaultValue: '',
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New FileMaker Calendar',
						format: 'name',
						helptext:
							"The name you would like to use to display the source in DayBack's sidebar where calendars are toggled on and off. This name is for display purposes only and need not match the actual table occurence name in FileMaker.",
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(68, 68, 242, 0.85)',
						format: 'colorPicker',
						helptext:
							'The color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
       						 <div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
         				...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							"Designating a calendar as Read-Only will prevent any editing or deleting of records via DayBack's popover or via drag and drop. This is an interface layer only, used to prevent requests from going to FileMaker and failing due to permissions or other access restrictions.",
					},
					allowUnscheduled: {
						setting: 'allowUnscheduled',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Allow unscheduled events',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'When enabled and a boolean unscheduled field is mapped you can mark events as unscheduled and they will not show in the main calendar view but in the unscheduled section instead. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					queryOnGeocode: {
						setting: 'queryOnGeocode',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Limit to map boundary',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will change the way events are queried. Rather than query based on dates the events will be queried based on geocode inside the map boundary.',
					},
					externalEdits: {
						setting: 'externalEdits',
						visible: true,
						displayValue: 'Edit Events on Layout',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Use a FileMaker layout to edit events rather than DayBack\'s built-in popover. If you select "Yes," you will then specify the name of layout to use. DayBack will use the "Open Event Record - DayBack" script in FileMaker to open your event on the selected layout in a card window. Learn more here: <a href="https://docs.dayback.com/article/176-editing-in-your-own-filemaker-layout" target="_blank">Editing in Your Own FileMaker Layout</a>.',
					},
					editLayoutName: {
						setting: 'editLayoutName',
						visible: true,
						displayValue: 'Edit Layout Name',
						defaultValue: '',
						helptext:
							'The name of the FileMaker layout you\'ll use for editing events instead of the DayBack popover. This option is only available if "Edit Events on Layout" is set to "Yes" above. Be sure to choose a layout based on the same base table occurrence as the layout specified in “Layout Name” above.',
						format: 'select',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'contact', label: 'Contact'},
							{id: 'project', label: 'Project'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers" target="_blank">Learn more about drawers here.</a>',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: false,
						format: 'defaultResource',
					},
					requireFilters: {
						setting: 'requireFilters',
						visible: true,
						displayValue:
							'Don’t show items unless the calendar is filtered',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Calendars with this setting set to "Yes" will not load items unless filters are applied. <a id="requireFiltersHelp" href="https://docs.dayback.com/article/137-speed" target="_blank">Learn more.</a>',
					},
					includeFilters: {
						setting: 'includeFilters',
						visible: true,
						displayValue:
							'Include resource and status filters in query?',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Calendars with this setting set to "Yes" will include the status and resource filters in the FileMaker finds in addition to the date range criteria. This can potentially increase performance for large data sets. However, it may slow down smaller data sets, so some experimentation should be done to see which setting gives your organization the best performance. <a href="https://docs.dayback.com/article/137-speed" target="_blank">Learn more.</a>',
					},
					allowRelated: {
						setting: 'allowRelated',
						visible: false,
						defaultValue: true,
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
				},
			};
		}

		function fieldMap() {
			return {
				allDay: {
					setting: 'allDay',
					visible: false,
					displayValue: '',
					defaultValue: '',
					required: false,
					unused: false,
					helptext: '',
				},
				eventID: {
					setting: 'eventID',
					visible: true,
					displayValue: 'Event Id',
					defaultValue: '',
					required: true,
					unused: false,
					helptext: 'The primary key or unique ID in your table.',
				},
				dateStart: {
					setting: 'dateStart',
					visible: true,
					displayValue: 'Date start',
					defaultValue: '',
					hideAdvanced: true,
					required: (calendarSource) => {
						return (
							!calendarSource.isMapOnly ||
							!calendarSource.queryOnGeocode
						);
					},
					unused: false,
					helptext:
						'The start date for your event: must be a date field, not a timestamp or text field. Note that if you only have one date for your event (a deadline, for example) that goes here.',
				},
				dateEnd: {
					setting: 'dateEnd',
					visible: true,
					displayValue: 'Date end',
					defaultValue: '',
					hideAdvanced: true,
					required: false,
					unused: false,
					helptext:
						'The end date for your event: must be a date field, not a timestamp. If you do not have an end date (or only have one date for your event) just leave this blank and uncheck "enabled."',
				},
				timeStart: {
					setting: 'timeStart',
					visible: true,
					displayValue: 'Time start',
					defaultValue: '',
					hideAdvanced: true,
					required: false,
					unused: false,
					helptext:
						'The time your event begins: must be a time field. Note that if you only have one time for your event (a deadline, for example) that goes here.',
				},
				timeEnd: {
					setting: 'timeEnd',
					visible: true,
					displayValue: 'Time end',
					defaultValue: '',
					hideAdvanced: true,
					required: false,
					unused: false,
					helptext:
						"The end time for your event: must be a time field. If you do not have an end time (or only have one time for your event) please create a field for this anyway. This field isn't absolutely required, but DayBack presents a more coherant interface when you have a field mapped here.",
				},
				start: {
					setting: 'start',
					visible: true,
					displayValue: 'TimestampNum calc start',
					defaultValue: 'DBk_TimestampStartCalcNum',
					labelValue: 'Start',
					required: (calendarSource) => {
						return (
							!calendarSource.isMapOnly ||
							!calendarSource.queryOnGeocode
						);
					},
					unused: false,
					helptext:
						'The event start timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents_DayBack::DBk_TimestampstartCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; ts = TimeStart  // Your event's start time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'&nbsp; Timestamp ( ds ; Max ( ts ; 0 ) ) <br>' +
						'&nbsp; ) <br>' +
						')' +
						'</div>' +
						'<br><br>This is one of the <a href="https://docs.dayback.com/article/175-required-fields" target="_blank">required fields</a> you must add to your table.<br><br><br>',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'TimestampNum calc end',
					defaultValue: 'DBk_TimestampEndCalcNum',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext:
						'The event end timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents_DayBack::DBk_TimestampEndCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; de = DateEnd ; // Your event's end date field <br>" +
						"&nbsp; ts = TimeStart ; // Your event's start time field <br>" +
						"&nbsp; te = TimeEnd ; // Your event's end time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; spanmidnight = not IsEmpty ( te ) and te < ts <br><br>' +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'Timestamp ( <br>' +
						'&nbsp; If ( spanmidnight and IsEmpty ( de ) ; ds + 1 ; Max ( ds ; de ) ) <br>' +
						'; <br>' +
						'&nbsp; If ( IsEmpty ( te ) ; Max ( ts ; 0 ) ; te ) <br>' +
						'&nbsp; ) <br>' +
						'&nbsp; ) <br>' +
						')' +
						'</div>' +
						'<br><br>This is one of the <a href="https://docs.dayback.com/article/175-required-fields" target="_blank">required fields</a> you must add to your table.<br><br><br>',
				},
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Event display calc',
					defaultValue: 'DBk_EventSummaryCalc',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'This is what shows as text for your event in the calendar before you click on an event; it\'s is how the event renders in the month view, for example. This is usually a calculated field combining the title of the event with some information about the event\'s description or related contact. If you\'re using DayBack.fmp12 this is the field SampleEvents_DayBack::DBk_EventSummaryCalc and you can add that field to your table, or copy it\'s definition below.<br><br>If you want to <a href="https://docs.dayback.com/article/111-event-styles" target="_blank">add icons or other styles</a> to yor events, you\'ll do so in the definition of this calc field.<br><br>You\'ll see that our example also <strong>substitutes carriage returns</strong> and you will want to do the same in your version of this field. Here is an example: <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Edit the lines below to reflect the fields in your events table ===== <br><br>' +
						'&nbsp; s = Summary ; // the title of your event <br>' +
						"&nbsp; desc = Description ;  // your event's description or notes field <br>" +
						"&nbsp; cont = SampleContacts_DayBack::NameFull ; // your contact's name (optional) <br>" +
						"&nbsp; ph = SampleContacts_DayBack::Phone ; // your contact's phone number (optional) <br>" +
						"&nbsp; em = SampleContacts_DayBack::Email ; // your contact's email (optional) <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; d = Left ( desc ; 300  ) <br><br>' +
						']; <br><br>' +
						'// ===== Use the list function to concatenate different fields together ===== <br><br>' +
						'// ===== Then substitute out any carriage returns ===== <br><br>' +
						'Substitute ( List ( s ; cont ; ph ;  em ; d ) ; "¶" ; "\n" ) <br><br>' +
						')<br><br><br>' +
						'</div>',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Summary',
					defaultValue: '',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext:
						'This is the field shown in the event popover as the title or summary; this field should be editable (i.e. not a calc). In contrast to the "Display" field above, this is the field your users will type into for the name of their event.',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: '',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext: 'Description Text Area in the pop-over.',
				},
				location: {
					setting: 'location',
					visible: true,
					displayValue: 'Location',
					defaultValue: '',
					labelValue: 'Location',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "location" --this is also the field DayBack will use to generate a map.',
				},
				geocode: {
					setting: 'geocode',
					visible: true,
					displayValue: 'GeoCode',
					defaultValue: '',
					labelValue: 'GeoCode',
					required: (calendarSource) => {
						return (
							calendarSource.isMapOnly &&
							calendarSource.queryOnGeocode
						);
					},
					unused: false,
					helptext:
						'Latitude and longitude values separated by a comma',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource',
					defaultValue: '',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext:
						'Resources are anything in your organization that gets overscheduled: people, trucks, rooms, or business processes. They drive the Resource views in DayBack (Schedule and Grid views) and are standard filters alongside "status".<br><br>The field selected here is generally the name of a person or piece of equipment in the event\'s record. Learn more about resources <a href="https://docs.dayback.com/article/43-resources-overview" target="_blank">here</a> and find specfic tips for mapping the resource field here: <a href="https://docs.dayback.com/article/177-mapping-the-resource-field" target="_blank">Mapping the Resource Field</a>.<br><br>Resource should be an editable field, but if you need to use a calculated field, you can learn how here: <a href="https://docs.dayback.com/article/188-using-calculated-fields-for-status-or-resource-in-filemaker" target="_blank">Using Calcs for Statuses or Resources.</a>',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: '',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext:
						'The status of the event. Statuses color code your events and are one of the calendar\'s primary filters. Learn more about statuses <a href="https://docs.dayback.com/article/21-event-colors" target="_blank">here</a>.<br><br>Status should be an editable field, but if you need to use a calculated field, you can learn how here: <a href="https://docs.dayback.com/article/188-using-calculated-fields-for-status-or-resource-in-filemaker" target="_blank">Using Calcs for Statuses or Resources.</a>',
				},
				tags: {
					setting: 'tags',
					visible: true,
					displayValue: 'Tags',
					defaultValue: 'DBk_Tags',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "tags" --this is also the field DayBack will use for certain meta-data about your events.',
				},
				unscheduled: {
					setting: 'unscheduled',
					visible: true,
					displayValue: 'Unscheduled',
					defaultValue: '',
					labelValue: 'Unscheduled',
					required: false,
					unused: false,
					helptext:
						'The field that holds a boolean value to indicate if the event should be treated as unscheduled. Map to a Number field. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
				},
				contactID: {
					setting: 'contactID',
					visible: true,
					displayValue: 'Contact id',
					defaultValue: '',
					required: false,
					unused: false,
					related: 'contactName',
					helptext:
						"Optional: If your event is linked to a contact, this is the field where you store the event's contact ID. (This is the field in your events table that holds the id for the event's contact.)",
				},
				contactName: {
					setting: 'contactName',
					visible: true,
					displayValue: 'Contact name',
					defaultValue: '',
					labelValue: 'Contact',
					required: false,
					unused: false,
					related: 'contactID',
					helptext:
						"Optional: If you mapped a contact ID above, this is the related field which stores the contact's name. For example: SampleContacts_DayBack::NameFull.",
				},
				projectID: {
					setting: 'projectID',
					visible: true,
					displayValue: 'Project id',
					defaultValue: '',
					required: false,
					unused: false,
					related: 'projectName',
					helptext:
						"Optional: If your event is linked to a project, this is the field where you store the event's project ID. (This is the field in your events table that holds the id for the event's project.)",
				},
				projectName: {
					setting: 'projectName',
					visible: true,
					displayValue: 'Project name',
					defaultValue: '',
					labelValue: 'Project',
					required: false,
					unused: false,
					related: 'projectID',
					helptext:
						"Optional: If you mapped a project ID above, this is the related field which stores the project's name. For example: SampleProjects_DayBack::Name.",
				},
				recurringEventID: {
					setting: 'recurringEventID',
					visible: true,
					displayValue: 'Repeating id',
					defaultValue: '',
					labelValue: 'Repeating id',
					required: false,
					unused: false,
					helptext:
						'Optional: If you’re using repeating events, map this field to the field DBk_Repeating_id you created as part of our integration instructions. This is the field that tells a given repetition, which group of repeating events it belongs to. It is the ID of the first event in the repetition chain.',
				},
				featuredImage: {
					setting: 'featuredImage',
					visible: false,
					displayValue: 'Featured Image URL',
					labelValue: 'Featured Image URL',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'URL for a featured image shown when viewing event in the popover.',
				},
				featuredImageClass: {
					setting: 'featuredImageClass',
					visible: false,
					displayValue: 'Featured Image Class',
					labelValue: 'Featured Image Class',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'CSS class for a featured image shown when viewing event. This is any class name you want and can be used when creating custom CSS.',
				},
				featuredImageThumbnail: {
					setting: 'featuredImageThumbnail',
					visible: false,
					displayValue: 'Featured Image Thumbnail URL',
					labelValue: 'Featured Image Thumbnail URL',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'URL for a featured image thumbnail shown on events in Horizon view.',
				},
				featuredImageThumbnailClass: {
					setting: 'featuredImageThumbnailClass',
					visible: false,
					displayValue: 'Featured Image Thumbnail Class',
					labelValue: 'Featured Image Thumbnail Class',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'CSS class for a featured image thumbnail shown on events. This is any class name you want and can be used when creating custom CSS.',
				},
			};
		}

		function unusedMap() {
			return {
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}
		function hiddenFieldMap() {
			return {};
		}
		function readOnlyFieldMap() {
			return {};
		}
	}
})();
